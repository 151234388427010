const { useStaticQuery, graphql } = require("gatsby");

export const useSiteTitelQuery = () => (
  useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
);
