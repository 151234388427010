import { graphql, useStaticQuery } from "gatsby";

export const useDocsMarkdownPages = () => {
  const { allMarkdownRemark: { nodes } } = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            slug
            title
          }
        }
      }
    }
  `);

  return nodes;
};
